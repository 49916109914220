import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import AppBar from '@material-ui/core/AppBar';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';

import DesktopToolbar from './desktop-toolbar';
import MobileToolbar from './mobile-toolbar';

const useStyles = makeStyles(theme => ({
    appContainer: {
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
    },
    headerContainer: {
        flexGrow: 0,
        flexBasis: 0,
    },
    mainContainer: {
        padding: 0,
        maxWidth: '100%',
        flexGrow: 1,
        flexBasis: '100%',
    },
    footerContainer: {
        paddingTop: 50,
        display: 'flex',
        justifyContent: 'start',
        flexGrow: 0,
        flexBasis: 0,
    }
}));

const Layout = ({ location, width, title, children }) => {
    const classes = useStyles();

    const data = useStaticQuery(graphql`
        query LayoutQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `)

    const siteTitle = data.site.siteMetadata.title;

    const Toolbar = (props) => isWidthUp('sm', width) ? <DesktopToolbar {...props} /> : <MobileToolbar {...props} />;

    return (
        <div className={classes.appContainer}>
            <div className={classes.headerContainer}>
                <header>
                    <AppBar position="static">
                        <Toolbar siteTitle={siteTitle} />
                    </AppBar>
                </header>
            </div>
            <Container className={classes.mainContainer}>
                <main>
                    {children}
                </main>
            </Container>
            <div className={classes.footerContainer}>
                <footer>
                    <div style={{padding: '20px'}}>
                    </div>
                </footer>
            </div>
        </div>
    )
};

export default withWidth()(Layout);
