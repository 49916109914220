import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby-theme-material-ui';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    menuLink: {
        fontFamily: 'Alef',
        fontWeight: '300',
        color: 'white',
        fontSize: '1.7em',
        padding: '0 20px',

        '&:hover, &:focus': {
            color: theme.palette.tertiary.main,
        },

        '&.active': {
            color: theme.palette.tertiary.main,
            fontWeight: 'bold'
        }
    }
}));

const MenuLink = ({ to, label, children, inputRef, ...rest }) => {
    const classes = useStyles();
    const isActive = window.location.pathname === to || window.location.pathname === `${to}/`;

    return (
        <Link
            ref={inputRef}
            to={to}
            color="textPrimary"
            classes={{
                root: `${classes.menuLink} ${isActive ? 'active' : ''}`
            }}
            underline="none"
            {...rest}
        >
            {label || children}
        </Link>
    );
};

MenuLink.defaultProps = {
    label: null,
    children: null,
    inputRef: null,
};

MenuLink.propTypes = {
    to: PropTypes.string.isRequired,
    label: PropTypes.string,
    children: PropTypes.node,
    inputRef: PropTypes.func,
};

export default forwardRef((linkProps, ref) => (<MenuLink inputRef={ref} {...linkProps} />));
