import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby-theme-material-ui';
import MuiToolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';

import Logo from '../../content/assets/default-monochrome-white.svg';

import MenuLink from './menu-link';

const useStyles = makeStyles(theme => ({
    menuLogoWrapper: {
        flexGrow: 1,
        marginRight: theme.spacing(2),
        padding: '10px 0 5px',
    },
    menuLogo: {
        height: 50,
    },
}));

const DesktopToolbar = ({ siteTitle }) => {
    const rootPath = `${__PATH_PREFIX__}/`;
    const classes = useStyles();

    return (
        <MuiToolbar role="navigation">
            <div className={classes.menuLogoWrapper}>
                <Link to={`/`}>
                    <Logo alt={siteTitle} className={classes.menuLogo} />
                </Link>
            </div>
            <MenuLink
                to={`${rootPath}blog`}
                label="blog"
            />
            <MenuLink
                to={`${rootPath}works`}
                label="works"
            />
            <MenuLink
                to="https://egghead.io/instructors/erin-doyle"
                label="egghead.io"
                target="_blank"
                rel="noopener noreferrer"
            />
        </MuiToolbar>
    );
};

DesktopToolbar.propTypes = {
    siteTitle: PropTypes.string.isRequired
};

export default DesktopToolbar;
