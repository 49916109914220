import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby-theme-material-ui';
import MuiToolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Drawer from '@material-ui/core/Drawer';
import BookTwoToneIcon from '@material-ui/icons/BookTwoTone';
import PermCameraMicTwoToneIcon from '@material-ui/icons/PermCameraMicTwoTone';
import { makeStyles } from '@material-ui/core/styles';

import EggoLogo from '../../content/assets/eggo.svg';

const useStyles = makeStyles(theme => ({
    menuLogoWrapper: {
        flexGrow: 1,
        marginRight: theme.spacing(2),
        padding: '10px 0 5px',
    },
    menuButton: {
        color: 'white',
    },
    list: {
        width: 250,
    },
    listItemText: {
        fontFamily: 'Alef',
        fontWeight: '300',
        fontSize: '1.7em',
    },
    eggoLogo: {
        height: 30,
    }
}));

const MobileToolbar = ({ siteTitle }) => {
    const data = useStaticQuery(graphql`query MobileLogoQuery {
        mobileLogo: file(absolutePath: {regex: "/logo.png/"}) {
          childImageSharp {
            gatsbyImageData(height: 50, placeholder: TRACED_SVG, layout: FIXED)
          }
        }
      }
    `);
    const image = getImage(data.mobileLogo.childImageSharp.gatsbyImageData);

    const rootPath = `${__PATH_PREFIX__}/`;
    const classes = useStyles();

    const [isDrawerOpen, setDrawerOpen] = useState(false);

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setDrawerOpen(open);
    };

    const MenuList = () => (
        <div
            className={classes.list}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <List component="nav" aria-label="nav menu">
                <ListItem button component={Link} to={`${rootPath}blog`}>
                    <ListItemIcon>
                        <BookTwoToneIcon />
                    </ListItemIcon>
                    <ListItemText
                        className={classes.listItemText}
                        disableTypography
                        primary="blog"
                    />
                </ListItem>
                <ListItem button component={Link} to={`${rootPath}works`}>
                    <ListItemIcon>
                        <PermCameraMicTwoToneIcon />
                    </ListItemIcon>
                    <ListItemText
                        className={classes.listItemText}
                        disableTypography
                        primary="works"
                    />
                </ListItem>
                <ListItem button component={Link} to="https://egghead.io/instructors/erin-doyle" target="_blank">
                    <ListItemIcon>
                        <EggoLogo className={classes.eggoLogo} />
                    </ListItemIcon>
                    <ListItemText
                        className={classes.listItemText}
                        disableTypography
                        primary="egghead.io"
                    />
                </ListItem>
            </List>
        </div>
    );

    return (
        <MuiToolbar>
            <div className={classes.menuLogoWrapper}>
                <Link to={`/`}><GatsbyImage alt={siteTitle} image={image} /></Link>
            </div>
            <IconButton
                edge="start"
                className={classes.menuButton}
                aria-label={isDrawerOpen ? 'close nav menu' : 'open nav menu'}
                onClick={toggleDrawer(true)}
            >
                <MenuIcon />
            </IconButton>
            <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer(false)}>
                <MenuList />
            </Drawer>
        </MuiToolbar>
    );
};

MobileToolbar.propTypes = {
    siteTitle: PropTypes.string.isRequired,
};

export default MobileToolbar;
